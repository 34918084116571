import { FunctionComponent, useEffect, useState } from "react";
import { menuItems } from "./Menu";
import { Avatar, Divider, Skeleton } from "@mantine/core";
import { IconBrandJavascript } from "@tabler/icons-react";
import { getTechnologiesData } from "../hooks/data";

interface TechnologiesSectionComponentProps {
  currentComponent: string;
  changeComponent: (component: string) => void;
}

const TechnologiesSectionComponent: FunctionComponent<
  TechnologiesSectionComponentProps
> = ({ currentComponent, changeComponent }) => {
  const [sectionOnHover, setSectionOnHover] = useState<boolean>(false);

  const componentLabel = "Technologies";

  //   Check if the current component is selected by user in the menu
  useEffect(() => {
    const menuItem = menuItems.filter((item) => item.label === componentLabel);
    if (currentComponent === menuItem[0].label) {
      setSectionOnHover(true);
    } else {
      setSectionOnHover(false);
    }
  }, [currentComponent]);

  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  // Get technologies
  const [technologies, setTechnologies] = useState<any>([]);

  const fetchTechnologies = async () => {
    await getTechnologiesData()
      .then((data) => {
        setTechnologies(data);
      })
      .finally(() => {
        setDataLoaded(true);
      });
  };

  useEffect(() => {
    fetchTechnologies();
  }, []);

  const techsRender = (techs: any[]) => {
    return techs.map((tech, index) => (
      <div key={index}>
        {tech.name}
        <div className="flex items-center justify-center">
          <Avatar src={tech.image} alt={tech.name} />
        </div>
      </div>
    ));
  };

  // Format name
  const formatName = (name: string): string => {
    const capitalized = name.charAt(0).toUpperCase() + name.slice(1);
    return capitalized.replace(/_/g, " ");
  };

  return (
    <div
      onMouseEnter={() => {
        const menuItem = menuItems.filter(
          (item) => item.label === componentLabel
        );
        changeComponent(menuItem[0].label);
        setSectionOnHover(true);
      }}
      onMouseLeave={() => {
        changeComponent("");
        setSectionOnHover(false);
      }}
      className={`select-none transition-all duration-200 opacity-70 overflow-x-hidden ${
        sectionOnHover && " opacity-95"
      }`}
    >
      <Divider
        my="xs"
        label={
          <>
            <span
              className={`text-xl font-black transition-all duration-200 flex gap-x-3 ${
                sectionOnHover && "text-white"
              }`}
            >
              <div className="flex justify-center items-center">
                <IconBrandJavascript />
              </div>
              Technologies
            </span>
          </>
        }
        labelPosition="center"
      />

      {dataLoaded ? (
        // ! ===================== Technologies list =====================
        <div className="">
          {technologies.map(
            ({ category, tech }: { category: string; tech: any[] }) => {
              return (
                <div key={category} className="mb-10 ">
                  <div className="text-center mb-2 text-xl font-black">{formatName(category)}</div>

                  {tech.length > 7 ? (
                    <div className="flex overflow-hidden group gap-x-16">
                      <div className="flex space-x-16 animate-loop-scroll group-hover:paused">
                        {techsRender(tech)}
                      </div>
                      <div
                        className="flex space-x-16 animate-loop-scroll group-hover:paused"
                        aria-hidden="true"
                      >
                        {techsRender(tech)}
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center justify-evenly">
                      {techsRender(tech)}
                    </div>
                  )}
                </div>
              );
            }
          )}
        </div>
      ) : (
        // ! ===================== Skeleton loader =====================
        <>
          <div className="flex gap-x-5 items-center justify-evenly">
            {Array.from({ length: 8 }).map((_, index) => (
              <Skeleton height={50} circle mb="xl" key={index} />
            ))}
          </div>
          <div className="flex gap-x-5 items-center justify-evenly">
            {Array.from({ length: 8 }).map((_, index) => (
              <Skeleton height={50} circle mb="xl" key={index} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default TechnologiesSectionComponent;
