import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

// Mantine
import "@mantine/core/styles.css";
import { MantineProvider } from "@mantine/core";

// Pages
import MainPage from "./pages/MainPage";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <MantineProvider defaultColorScheme="dark">
      <MainPage />
    </MantineProvider>
  </React.StrictMode>
);
