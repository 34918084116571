import { FunctionComponent, useEffect, useState } from "react";

// Mantine
import { ActionIcon, Divider, Image, Skeleton, Tooltip } from "@mantine/core";
import {
  IconBrandGithubFilled,
  IconBrandLinkedin,
  IconHomeFilled,
  IconMailFilled,
  IconMapPinFilled,
} from "@tabler/icons-react";

import { isMobile } from "react-device-detect";

import { menuItems } from "./Menu";

interface ContactSectionComponentProps {
  currentComponent: string;
  changeComponent: (component: string) => void;
}

const ContactSectionComponent: FunctionComponent<
  ContactSectionComponentProps
> = ({ currentComponent, changeComponent }) => {
  const [sectionOnHover, setSectionOnHover] = useState<boolean>(false);

  const componentLabel = "Contact";

  //   Check if the current component is selected by user in the menu
  useEffect(() => {
    const menuItem = menuItems.filter((item) => item.label === componentLabel);
    if (currentComponent === menuItem[0].label) {
      setSectionOnHover(true);
    } else {
      setSectionOnHover(false);
    }
  }, [currentComponent]);

  const [imageLoded, setImageLoaded] = useState<boolean>(false);

  return (
    <span
      onMouseEnter={() => {
        const menuItem = menuItems.filter(
          (item) => item.label === componentLabel
        );
        changeComponent(menuItem[0].label);
        setSectionOnHover(true);
      }}
      onMouseLeave={() => {
        changeComponent("");
        setSectionOnHover(false);
      }}
      className={` transition-all duration-200 opacity-70 ${
        sectionOnHover &&
        (isMobile ? "opacity-95" : "scale-105 -translate-x-3 opacity-95")
      }`}
      style={{ fontSize: isMobile ? "0.7rem" : "" }}
    >
      {/* // ! ===================== My image ===================== */}
      <div className={` ${isMobile && 'h-1/2'} `}>
        <Image
          radius="md"
          src="https://firebasestorage.googleapis.com/v0/b/portfolio-5bbec.appspot.com/o/me.jpeg?alt=media&token=3944453a-5462-49c3-ad9d-b4a20bac884b"
          alt="My image"
          h={250}
          w="auto"
          fit="contain"
          className={`opacity-75 transition-all duration-200 ${
            sectionOnHover && " opacity-95 "
          } `}
          onLoad={() => setImageLoaded(true)}
        />
      </div>
      {!imageLoded && <Skeleton height={250} radius="xl" />}



      {/* // ! ===================== Email, Address, GitHub, LinkedIn ===================== */}
      <div className="">
        <Divider
          my="xs"
          label={
            <>
              <span
                className={`text-xl font-black transition-all duration-200 flex gap-x-3  ${
                  sectionOnHover && "text-white "
                }`}
              >
                <div className="flex justify-center items-center">
                  <IconMapPinFilled />
                </div>
                Contact
              </span>
            </>
          }
          labelPosition="center"
          className="select-none"
        />

        {/* // ? ========== Container ========== */}
        <div className="grid gap-y-5">
          {/* // ! ========== Email ========== */}
          <div
            className={`flex gap-x-3 hover:translate-x-1 transition-all duration-200  `}
          >
            <Tooltip
              label="Click to mail me"
              position="right"
              offset={5}
              withArrow
            >
              <div
                className="flex gap-x-3 hover:cursor-pointer"
                onClick={() => {
                  window.open("mailto: krz.sluzalek@gmail.com");
                }}
              >
                <IconMailFilled size={isMobile ? '1.2rem':'1.5rem'} />
                <span
                  className={`opacity-70 hover:opacity-100 transition-all duration-200 ${
                    sectionOnHover && "opacity-95"
                  }`}
                >
                  krz.sluzalek@gmail.com
                </span>
              </div>
            </Tooltip>
          </div>

          {/* // ! ========== Address ========== */}
          <div className="flex gap-x-3">
            <IconHomeFilled size={isMobile ? '1.2rem':'1.5rem'} />
            <span
              className={`opacity-70 hover:cursor-default hover:opacity-100 transition-all duration-200 ${
                sectionOnHover && "opacity-95"
              }`}
            >
              Katowice, Poland
            </span>
          </div>

          {/* // ! ========== GitHub, LinkedIn ========== */}
          <div className=" gap-x-3 grid grid-cols-2 ">
            <div className="grid place-items-center">
              <Tooltip label="GitHub" position="bottom" offset={5} withArrow>
                <ActionIcon
                  variant="transparent"
                  aria-label="GitHubLink"
                  onClick={() =>
                    window.open("https://github.com/Vercixxx/", "_blank")
                  }
                  size={isMobile ? "md" : "xl"}
                  radius="xl"
                  className="opacity-50 hover:opacity-100 hover:scale-105 hover:-translate-y-1 transition-all duration-300 ease-in-out hover:shadow-[0_0_30px_rgba(99,102,241,0.3)]"
                >
                  <IconBrandGithubFilled color="white" size={40} />
                </ActionIcon>
              </Tooltip>
            </div>

            <div className="grid place-items-center">
              <Tooltip label="LinkedIn" position="bottom" offset={5} withArrow>
                <ActionIcon
                  variant="transparent"
                  aria-label="LinkedInLink"
                  onClick={() =>
                    window.open(
                      "https://linkedin.com/in/krzysztof-sluzalek",
                      "_blank"
                    )
                  }
                  size={isMobile ? "md" : "xl"}
                  radius="xl"
                  className="opacity-50 hover:opacity-100 hover:scale-105 hover:-translate-y-1 transition-all duration-300 ease-in-out hover:shadow-[0_0_30px_rgba(99,102,241,0.3)]"
                >
                  <IconBrandLinkedin color="white" size={40} />
                </ActionIcon>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};

export default ContactSectionComponent;
