import { FunctionComponent, useEffect, useState } from "react";
import { menuItems } from "./Menu";
import { Avatar, Divider } from "@mantine/core";
import { IconSchool } from "@tabler/icons-react";
import { isMobile } from "react-device-detect";
interface EducationSectionComponentProps {
  currentComponent: string;
  changeComponent: (component: string) => void;
}

const EducationSectionComponent: FunctionComponent<
  EducationSectionComponentProps
> = ({ currentComponent, changeComponent }) => {
  const [sectionOnHover, setSectionOnHover] = useState<boolean>(false);

  const componentLabel = "Education";

  //   Check if the current component is selected by user in the menu
  useEffect(() => {
    const menuItem = menuItems.filter((item) => item.label === componentLabel);
    if (currentComponent === menuItem[0].label) {
      setSectionOnHover(true);
    } else {
      setSectionOnHover(false);
    }
  }, [currentComponent]);

  return (
    <div
      onMouseEnter={() => {
        const menuItem = menuItems.filter(
          (item) => item.label === componentLabel
        );
        changeComponent(menuItem[0].label);
        setSectionOnHover(true);
      }}
      onMouseLeave={() => {
        changeComponent("");
        setSectionOnHover(false);
      }}
      className={`select-none transition-all duration-200 opacity-70 ${
        sectionOnHover &&
        (isMobile ? "opacity-95" : "scale-105 translate-x-3 opacity-95")
      }`}
    >
      <Divider
        my="xs"
        label={
          <>
            <span
              className={`text-xl font-black transition-all duration-200 flex gap-x-3 ${
                sectionOnHover && "text-white"
              }`}
            >
              <div className="flex justify-center items-center">
                <IconSchool />
              </div>
              Education
            </span>
          </>
        }
        labelPosition="center"
      />

      <div className="grid grid-cols-1 gap-y-3">
        <div
          className="cursor-pointer hover:translate-x-3 transition-all duration-200"
          onClick={() => {
            window.open("https://us.edu.pl/en/");
          }}
        >
          <div className="flex gap-x-3">
            <div className="flex items-center justify-center">
              <Avatar
                src={
                  "https://firebasestorage.googleapis.com/v0/b/portfolio-5bbec.appspot.com/o/education%2Fus_en2.png?alt=media&token=f9986156-61ef-497a-abb2-50ab46c3f39a"
                }
              />
            </div>
            <div>
              <div className={`font-black ${isMobile ? "" : "text-xl"}`}>
                Applied computer science
              </div>

              <div className="text-sm text-justify">
                University of Silesia in Katowice{" "}
              </div>
              <div
                className={`text-justify ${isMobile ? "text-xs" : "text-sm"}`}
              >
                2021 - present
              </div>
            </div>
          </div>
        </div>

        <div
          className="cursor-pointer hover:translate-x-3 transition-all duration-200"
          onClick={() => {
            window.open("https://zse.edu.pl/");
          }}
        >
          <div className="flex gap-x-3">
            <div className="flex items-center justify-center">
              <Avatar
                src={
                  "https://firebasestorage.googleapis.com/v0/b/portfolio-5bbec.appspot.com/o/education%2Fzseii.png?alt=media&token=03e1aafd-2ae6-4658-b3e7-da14c7b3c34d"
                }
              />
            </div>
            <div>
              <div className={`font-black ${isMobile ? "" : "text-xl"}`}>
                Computer Science Technician
              </div>
              <div className="text-sm text-justify">
                Technical college - School Complex of Electronics and Computer
                Science
              </div>
              <div
                className={`text-justify ${isMobile ? "text-xs" : "text-sm"}`}
              >
                2016 - 2020
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationSectionComponent;
