import { ActionIcon, Tooltip } from "@mantine/core";
import { IconBrandGithubFilled, IconBrandLinkedin } from "@tabler/icons-react";
import { FunctionComponent } from "react";
import { isMobile } from "react-device-detect";

interface SocialLinksComponentProps {}

const SocialLinksComponent: FunctionComponent<
  SocialLinksComponentProps
> = () => {
  return (
    <>
      <Tooltip label="GitHub" position="left" offset={5} withArrow>
        <ActionIcon
          variant="transparent"
          aria-label="GitHubLink"
          onClick={() => window.open("https://github.com/Vercixxx/", "_blank")}
          size={isMobile ? "lg" : "xl"}
          radius="xl"
          className={` ${
            !isMobile &&
            "hover:scale-150 hover:-translate-x-2 transition-all duration-300 ease-in-out hover:shadow-[0_0_45px_rgba(99,102,241,0.3)]"
          } `}
        >
          <IconBrandGithubFilled color="white" size={40} />
        </ActionIcon>
      </Tooltip>

      <Tooltip label="LinkedIn" position="left" offset={5} withArrow>
        <ActionIcon
          variant="transparent"
          aria-label="LinkedInLink"
          onClick={() =>
            window.open("https://linkedin.com/in/krzysztof-sluzalek", "_blank")
          }
          size={isMobile ? "lg" : "xl"}
          radius="xl"
          className={` ${
            !isMobile &&
            "hover:scale-150 hover:-translate-x-2 transition-all duration-300 ease-in-out hover:shadow-[0_0_45px_rgba(99,102,241,0.3)]"
          } `}
        >
          <IconBrandLinkedin color="white" size={40} />
        </ActionIcon>
      </Tooltip>

      {/* Switch languages */}
      {/* <Tooltip label="Switch languages" position="left" offset={5} withArrow>
        <ActionIcon
          variant="transparent"
          aria-label="LinkedInLink"
          onClick={() =>
            window.open("https://linkedin.com/in/krzysztof-sluzalek", "_blank")
          }
          size={isMobile ? "lg" : "xl"}
          radius="xl"
          className={` ${
            !isMobile &&
            "hover:scale-150 hover:-translate-x-2 transition-all duration-300 ease-in-out hover:shadow-[0_0_45px_rgba(99,102,241,0.3)]"
          } `}
        >
          <IconLanguage color="white" size={40} />
        </ActionIcon>
      </Tooltip> */}
    </>
  );
};

export default SocialLinksComponent;
