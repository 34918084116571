import { FunctionComponent, useEffect, useState } from "react";
import { menuItems } from "./Menu";
import { Divider } from "@mantine/core";
import { IconWriting } from "@tabler/icons-react";
import { getAboutMeData } from "../hooks/data";
import { DocumentData } from "firebase/firestore";
import { isMobile } from "react-device-detect";

interface AboutMeSectionComponentProps {
  currentComponent: string;
  changeComponent: (component: string) => void;
}

const AboutMeSectionComponent: FunctionComponent<
  AboutMeSectionComponentProps
> = ({ currentComponent, changeComponent }) => {
  const [sectionOnHover, setSectionOnHover] = useState<boolean>(false);

  const componentLabel = "About me";

  //   Check if the current component is selected by user in the menu
  useEffect(() => {
    const menuItem = menuItems.filter((item) => item.label === componentLabel);
    if (currentComponent === menuItem[0].label) {
      setSectionOnHover(true);
    } else {
      setSectionOnHover(false);
    }
  }, [currentComponent]);

  // Get data from firebase
  const [aboutMeData, setAboutMeData] = useState<DocumentData>();

  const fetchAboutMeData = async () => {
    await getAboutMeData().then((data) => {
      setAboutMeData(data);
    });
  };

  // On load
  useEffect(() => {
    fetchAboutMeData();
  }, []);

  return (
    <div
      onMouseEnter={() => {
        const menuItem = menuItems.filter(
          (item) => item.label === componentLabel
        );
        changeComponent(menuItem[0].label);
        setSectionOnHover(true);
      }}
      onMouseLeave={() => {
        changeComponent("");
        setSectionOnHover(false);
      }}
      className={`select-none transition-all duration-200 opacity-70 ${
        sectionOnHover && "scale-105 translate-x-3 opacity-95"
      }`}
      style={{ fontSize: isMobile ? '0.7rem':'' }}
    >
      <Divider
        my="xs"
        label={
          <>
            <span
              className={`text-xl font-black transition-all duration-200 flex gap-x-3 ${
                sectionOnHover && "text-white"
              }`}
            >
              <div className="flex justify-center items-center">
                <IconWriting />
              </div>
              About me
            </span>
          </>
        }
        labelPosition="center"
      />

      <div
        className="p-2 text-justify"
        dangerouslySetInnerHTML={{
          __html: typeof aboutMeData === "string" ? aboutMeData : "",
        }}
      ></div>
    </div>
  );
};

export default AboutMeSectionComponent;
