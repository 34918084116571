import {
  IconBike,
  IconBrandJavascript,
  IconBriefcase2Filled,
  IconHtml,
  IconLanguage,
  IconMapPinFilled,
  IconSchool,
  IconWriting,
} from "@tabler/icons-react";
import { FunctionComponent } from "react";

interface MenuComponentProps {
  currentComponent: string;
  changeComponent: (component: string) => void;
}

export const menuItems = [
  {
    label: "Contact",
    icon: <IconMapPinFilled />,
    component: "Contact",
  },
  {
    label: "Languages",
    icon: <IconLanguage />,
    component: "Languages",
  },
  {
    label: "Hobbies",
    icon: <IconBike />,
    component: "Hobbies",
  },
  {
    label: "About me",
    icon: <IconWriting />,
    component: "About me",
  },
  {
    label: "Realizations",
    icon: <IconHtml />,
    component: "Realizations",
  },
  {
    label: "Education",
    icon: <IconSchool />,
    component: "Education",
  },
  {
    label: "Technologies",
    icon: <IconBrandJavascript />,
    component: "Technologies",
  },
  {
    label: "Experience",
    icon: <IconBriefcase2Filled />,
    component: "Experience",
  },
];

const MenuComponent: FunctionComponent<MenuComponentProps> = ({
  currentComponent,
  changeComponent,
}) => {
  // ! ===================== Item render =====================*/

  const menuItemRender = (item: any) => {
    return (
      <div
        className={` grid grid-cols-12 cursor-pointer opacity-55 hover:translate-x-1 hover:opacity-85 hover:font-black p-1 transition-all duration-200 mb-2  ${
          currentComponent === item.label
            ? "translate-x-1 font-black opacity-85"
            : ""
        } `}
        onMouseEnter={() => changeComponent(item.label)}
        onMouseLeave={() => changeComponent("")}
      >
        {/* // ! ===================== Icon =====================*/}
        <div className="col-span-3 flex items-center mr-4 ">{item.icon}</div>

        {/* // ! ===================== Label =====================*/}
        <div className="col-span-9 flex items-center text-xl">
          <span>{item.label}</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        {menuItems.map((item) => (
          <span key={item.label}>{menuItemRender(item)}</span>
        ))}
      </div>
    </>
  );
};

export default MenuComponent;
