import { FunctionComponent, useEffect, useRef, useState } from "react";
import { menuItems } from "./Menu";
import {
  ActionIcon,
  Button,
  Divider,
  Group,
  HoverCard,
  Image,
  Skeleton,
} from "@mantine/core";
import {
  IconBrandGithub,
  IconDeviceImac,
  IconHtml,
  IconX,
} from "@tabler/icons-react";
import { getRealizationsData } from "../hooks/data";

import "@mantine/carousel/styles.css";
import Autoplay from "embla-carousel-autoplay";
import { Carousel } from "@mantine/carousel";
import { isMobile } from "react-device-detect";

interface RelizationsSectionComponentProps {
  currentComponent: string;
  changeComponent: (component: string) => void;
}

const RelizationsSectionComponent: FunctionComponent<
  RelizationsSectionComponentProps
> = ({ currentComponent, changeComponent }) => {
  const [sectionOnHover, setSectionOnHover] = useState<boolean>(false);

  const componentLabel = "Realizations";

  //   Check if the current component is selected by user in the menu
  useEffect(() => {
    const menuItem = menuItems.filter((item) => item.label === componentLabel);
    if (currentComponent === menuItem[0].label) {
      setSectionOnHover(true);
    } else {
      setSectionOnHover(false);
    }
  }, [currentComponent]);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [realizationsData, setRealizationsData] = useState<any>([]);

  // Fetch data
  const fetchData = async () => {
    await getRealizationsData().then((data) => {
      setRealizationsData(data);
      setIsLoaded(true);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Carousell
  const autoplay = useRef(Autoplay({ delay: 2000 }));

  const realizationRender = (realization: any) => {
    if (!realization) {
      return <></>;
    }
    return (
      <div>
        {/* Name */}
        <div className="font-black text-xl">{realization.name}</div>

        <div className="grid grid-cols-2 gap-x-2">
          {/* Description */}
          <div
            className="text-sm text-justify"
            style={{ height: isMobile ? "" : "30dvh", fontSize: "0.65rem" }}
            dangerouslySetInnerHTML={{
              __html: realization.description,
            }}
          ></div>

          <div className="flex justify-center">
            {/* Image */}
            <Image
              src={realization.image_url}
              alt={realization.title}
              radius="md"
              className="col-span-1"
              fit="contain"
            />
          </div>
        </div>

        <div className="flex justify-evenly">
          {realization.github_link ? (
            <Button
              onClick={() => window.open(realization.github_link)}
              className="mt-2"
              size="sm"
              color="blue"
              radius="xl"
              leftSection={<IconBrandGithub />}
            >
              Github
            </Button>
          ) : (
            <div className="text-red-600/50">
              Github - not available (Private)
            </div>
          )}

          {/* Web page link */}
          {realization.link && (
            <Button
              onClick={() => window.open(realization.link)}
              className="mt-2"
              size="sm"
              color="blue"
              radius="xl"
              leftSection={<IconDeviceImac />}
            >
              Check it out
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      onMouseEnter={() => {
        const menuItem = menuItems.filter(
          (item) => item.label === componentLabel
        );
        changeComponent(menuItem[0].label);
        setSectionOnHover(true);
      }}
      onMouseLeave={() => {
        changeComponent("");
        setSectionOnHover(false);
      }}
      className={`select-none transition-all duration-200 opacity-70 ${
        sectionOnHover && " opacity-95"
      }`}
    >
      <Divider
        my="xs"
        label={
          <>
            <span
              className={`text-xl font-black transition-all duration-200 flex gap-x-3 ${
                sectionOnHover && "text-white"
              }`}
            >
              <div className="flex justify-center items-center">
                <IconHtml />
              </div>
              Relizations
              {isMobile && (
                <div className="">
                  <span className="text-xs font-light">
                    Click to see more details
                  </span>
                </div>
              )}
            </span>
          </>
        }
        labelPosition="center"
      />

      {isLoaded ? (
        <div className=" hover:cursor-pointer transition-all duration-200 ">
          <Carousel
            withIndicators
            height={230}
            plugins={[autoplay.current]}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.reset}
            loop
            draggable
            withControls={false}
            slideGap="md"
            slideSize="33.333333%"
          >
            {realizationsData.map((realization: any) => (
              <Carousel.Slide key={realization.id}>
                <Group justify="center">
                  <HoverCard
                    width={isMobile ? "" : "500"}
                    shadow="md"
                    position="top"
                    withArrow
                    arrowSize={10}
                    transitionProps={{
                      duration: 200,
                      timingFunction: "ease",
                    }}
                  >
                    <HoverCard.Target>
                      {/* Card */}
                      <div className="w-80 h-40 ">
                        <div className="hover:blur-sm transition-all duration-200">
                          <div className="text-center font-black">
                            {realization.name}
                          </div>
                          <Image
                            radius="md"
                            src={realization.image_url}
                            alt={realization.title}
                          />
                        </div>
                      </div>
                    </HoverCard.Target>
                    <HoverCard.Dropdown>
                      {realizationRender(realization)}
                    </HoverCard.Dropdown>
                  </HoverCard>
                </Group>
              </Carousel.Slide>
            ))}
          </Carousel>
        </div>
      ) : (
        <div className="flex gap-x-11">
          {Array.from({ length: 3 }).map((_, index) => (
            <Skeleton key={index} height={230}></Skeleton>
          ))}
        </div>
      )}
    </div>
  );
};

export default RelizationsSectionComponent;
