import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { db } from "./firebase";

export const getAboutMeData = async () => {
    try {
        const aboutMeRef = doc(db, "about", "about");
        const aboutMeDoc = await getDoc(aboutMeRef);

        const aboutMeData = aboutMeDoc.data();
        const description = aboutMeData?.description;


        return Promise.resolve(description);
    } catch (error: any) {
        console.error(error.message);
        return Promise.reject(error.message);
    }
};

export const getRealizationsData = async () => {

    try {
        const collectionRef = collection(db, "realizations");
        const collectionSnapshot = await getDocs(collectionRef);

        const collectionData = collectionSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }))


        return Promise.resolve(collectionData);
    } catch (error: any) {
        console.error(error.message);
        return Promise.reject(error.message);
    }


}

type TechnologyCategories = {
    frontend: string[];
    backend: string[];
    database: string[];
    mobileapp: string[];
    programing_languages: string[];
};


export const getTechnologiesData = async () => {

    try {
        const collectionRef = collection(db, 'technologies');
        const collectionSnapshot = await getDocs(collectionRef);

        const technologyCategories: TechnologyCategories = {
            frontend: [
                "React",
                "Redux",
                "Tailwind CSS",
                "Material UI",
                "Mantine",
                "Vue.js",
                "Vuex",
                "Pinia",
                "vuetify",
                "Stripe",
                "syncfusion",
                "tinymce",
                "Vite",
            ],
            backend: ["django", "drf"],
            database: ["PostgreSQL", "Firebase", "MySQL"],
            mobileapp: ["reactnative", "flutter"],
            programing_languages: ["dart", "javascript", "python", "typescript"],
        };

        const allData: any[] = [];

        for (const docSnapshot of collectionSnapshot.docs) {
            const techs: any[] = [];

            if (docSnapshot.id in technologyCategories) {
                const collectionsInDoc = technologyCategories[docSnapshot.id as keyof TechnologyCategories];

                for (const tech of collectionsInDoc) {
                    const techDocsRef = collection(db, 'technologies', docSnapshot.id, tech);
                    const techDocsSnapshot = await getDocs(techDocsRef);

                    techDocsSnapshot.forEach((doc) => {
                        techs.push(doc.data());
                    });
                }
            }

            allData.push({
                category: docSnapshot.id,
                tech: techs
            });
        }

        return Promise.resolve(allData);
    } catch (error: any) {
        console.error(error.message);
        return Promise.reject(error.message);
    }

}