import { FunctionComponent, useEffect, useState } from "react";

// Mantine
import { Divider, List } from "@mantine/core";
import {
  IconActivity,
  IconBarbell,
  IconBike,
  IconCarTurbine,
  IconMovie,
} from "@tabler/icons-react";

import { isMobile } from "react-device-detect";

import { menuItems } from "./Menu";

interface HobbiesSectionComponentProps {
  currentComponent: string;
  changeComponent: (component: string) => void;
}

const HobbiesSectionComponent: FunctionComponent<
  HobbiesSectionComponentProps
> = ({ currentComponent, changeComponent }) => {
  const [sectionOnHover, setSectionOnHover] = useState<boolean>(false);

  const componentLabel = "Hobbies";

  //   Check if the current component is selected by user in the menu
  useEffect(() => {
    const menuItem = menuItems.filter((item) => item.label === componentLabel);
    if (currentComponent === menuItem[0].label) {
      setSectionOnHover(true);
    } else {
      setSectionOnHover(false);
    }
  }, [currentComponent]);

  return (
    <div
      onMouseEnter={() => {
        const menuItem = menuItems.filter(
          (item) => item.label === componentLabel
        );
        changeComponent(menuItem[0].label);
        setSectionOnHover(true);
      }}
      onMouseLeave={() => {
        changeComponent("");
        setSectionOnHover(false);
      }}
      className={`select-none transition-all duration-200 opacity-70 ${
        sectionOnHover && (isMobile ? 'opacity-95':'scale-105 -translate-x-3 opacity-95')
      }`}
    >
      <div className="select-none">
        <Divider
          my="xs"
          label={
            <>
              <span
                className={`text-xl font-black transition-all duration-200 flex gap-x-3 ${
                  sectionOnHover && "text-white"
                }`}
              >
                <div className="flex justify-center items-center">
                  <IconBike />
                </div>
                Hobbies
              </span>
            </>
          }
          labelPosition="center"
        />

        <List
          className={` opacity-70 cursor-default transition-all duration-200 ${
            sectionOnHover && "opacity-95"
          } `}
        >
          <List.Item>
            <span className={`flex gap-x-3 ${isMobile ? "text-sm" : ""}`}>
              <IconBarbell />
              Exercise and weightlifting
            </span>
          </List.Item>
          <List.Item>
            <span className={`flex gap-x-3 ${isMobile ? "text-sm" : ""}`}>
              <IconActivity />
              Healthy lifestyle
            </span>
          </List.Item>
          <List.Item>
            <span className={`flex gap-x-3 ${isMobile ? "text-sm" : ""}`}>
              <IconCarTurbine />
              Car mechanics and car tuning
            </span>
          </List.Item>
          <List.Item>
            <span className={`flex gap-x-3 ${isMobile ? "text-sm" : ""}`}>
              <IconMovie />
              Science fiction movies and books
            </span>
          </List.Item>
        </List>
      </div>
    </div>
  );
};

export default HobbiesSectionComponent;
