import { FunctionComponent, useEffect, useState } from "react";
import { menuItems } from "./Menu";
import { Avatar, Divider } from "@mantine/core";
import { IconSchool, IconTruckDelivery } from "@tabler/icons-react";
import { isMobile } from "react-device-detect";

interface ExperienceSectionComponentProps {
  currentComponent: string;
  changeComponent: (component: string) => void;
}

const ExperienceSectionComponent: FunctionComponent<
  ExperienceSectionComponentProps
> = ({ currentComponent, changeComponent }) => {
  const [sectionOnHover, setSectionOnHover] = useState<boolean>(false);

  const componentLabel = "Experience";

  //   Check if the current component is selected by user in the menu
  useEffect(() => {
    const menuItem = menuItems.filter((item) => item.label === componentLabel);
    if (currentComponent === menuItem[0].label) {
      setSectionOnHover(true);
    } else {
      setSectionOnHover(false);
    }
  }, [currentComponent]);

  return (
    <div
      onMouseEnter={() => {
        const menuItem = menuItems.filter(
          (item) => item.label === componentLabel
        );
        changeComponent(menuItem[0].label);
        setSectionOnHover(true);
      }}
      onMouseLeave={() => {
        changeComponent("");
        setSectionOnHover(false);
      }}
      className={`select-none transition-all duration-200 opacity-70 ${
        sectionOnHover &&
        (isMobile ? "opacity-95" : "scale-105 -translate-x-3 opacity-95")
      }`}
    >
      <Divider
        my="xs"
        label={
          <>
            <span
              className={`text-xl font-black transition-all duration-200 flex gap-x-3 ${
                sectionOnHover && "text-white"
              }`}
            >
              <div className="flex justify-center items-center">
                <IconSchool />
              </div>
              Experience
            </span>
          </>
        }
        labelPosition="center"
      />
      <div className="grid grid-cols-1 gap-y-3">
        <div>
          <div className="flex gap-x-3">
            <div className="flex items-center justify-center">
              <IconTruckDelivery size={isMobile ? '2.5rem':'2.5rem'} />
            </div>
            <div>
              <div className={`font-black ${isMobile ? "" : "text-xl"}`}>
                Food delivery
              </div>

              <div className="text-sm ">Delivery of food to customers</div>
              <div
                className={`text-justify ${isMobile ? "text-xs" : "text-sm"}`}
              >
                2021 - present
              </div>
            </div>
          </div>
        </div>

        <div
          className="cursor-pointer hover:translate-x-3 transition-all duration-200"
          onClick={() => {
            window.open("https://aktin.pl/");
          }}
        >
          <div className="flex gap-x-3">
            <div className="flex items-center justify-center">
              <Avatar
                src={
                  "https://firebasestorage.googleapis.com/v0/b/portfolio-5bbec.appspot.com/o/experience%2Faktin.png?alt=media&token=1e4b15c1-c232-4ea8-923a-ae8b9d32f312"
                }
              />
            </div>
            <div>
              <div className={`font-black ${isMobile ? "" : "text-xl"}`}>
                Aktin SP. z o. o.
              </div>

              <div className="text-sm text-justify">Website administration</div>
              <div className="opacity-60">{"( internship )"}</div>
              <div
                className={`text-justify ${isMobile ? "text-xs" : "text-sm"}`}
              >
                2020 - 2021
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceSectionComponent;
