import { FunctionComponent, useEffect, useState } from "react";

import { menuItems } from "./Menu";
import { Divider, Rating } from "@mantine/core";
import { IconLanguage } from "@tabler/icons-react";
import { isMobile } from "react-device-detect";

interface LanguagesSectionComponentProps {
  currentComponent: string;
  changeComponent: (component: string) => void;
}

const LanguagesSectionComponent: FunctionComponent<
  LanguagesSectionComponentProps
> = ({ currentComponent, changeComponent }) => {
  const [sectionOnHover, setSectionOnHover] = useState<boolean>(false);

  const componentLabel = "Languages";

  //   Check if the current component is selected by user in the menu
  useEffect(() => {
    const menuItem = menuItems.filter((item) => item.label === componentLabel);
    if (currentComponent === menuItem[0].label) {
      setSectionOnHover(true);
    } else {
      setSectionOnHover(false);
    }
  }, [currentComponent]);

  return (
    <div
      onMouseEnter={() => {
        const menuItem = menuItems.filter(
          (item) => item.label === componentLabel
        );
        changeComponent(menuItem[0].label);
        setSectionOnHover(true);
      }}
      onMouseLeave={() => {
        changeComponent("");
        setSectionOnHover(false);
      }}
      className={`select-none transition-all duration-200 opacity-70 ${
        sectionOnHover && (isMobile ? 'opacity-95':'scale-105 -translate-x-3 opacity-95')
      }`}
      style={{ fontSize: isMobile ? "0.7rem" : "" }}
    >
      <div className="">
        <Divider
          my="xs"
          label={
            <>
              <span
                className={`text-xl font-black transition-all duration-200 flex gap-x-3 ${
                  sectionOnHover && "text-white"
                }`}
              >
                <div className="flex justify-center items-center">
                  <IconLanguage />
                </div>
                Languages
              </span>
            </>
          }
          labelPosition="center"
        />

        <div className="grid gap-y-4">
          {/* // ! ========== Polish ========== */}
          <div
            className={`opacity-70 cursor-default transition-all duration-200 flex gap-x-4 ${
              sectionOnHover && "opacity-95"
            }`}
          >
            Polish
            <div className="flex justify-center items-center">
              {" "}
              <Rating
                defaultValue={6}
                color="orange"
                count={6}
                readOnly
                size={isMobile ? "xs" : "md"}
              />
            </div>
          </div>

          {/* // ! ========== English ========== */}
          <div
            className={`opacity-70 cursor-default transition-all duration-200 flex gap-x-4 ${
              sectionOnHover && "opacity-95"
            }`}
          >
            English
            <div className="flex justify-center items-center">
              {" "}
              <Rating
                defaultValue={4}
                color="orange"
                count={6}
                readOnly
                size={isMobile ? "xs" : "md"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguagesSectionComponent;
