import { FunctionComponent, useState } from "react";

// Components
import SocialLinksComponent from "../components/SocialLinksComponent";
import TopSection from "../components/TopSection";
import MenuComponent from "../components/Menu";
import ContactSectionComponent from "../components/ContactSection";
import LanguagesSetcionComponent from "../components/LanguagesSection";
import HobbiesSectionComponent from "../components/HobbiesSection";
import TechnologiesSectionComponent from "../components/TechnologiesSection";
import AboutMeSectionComponent from "../components/AboutMeSection";
import RelizationsSectionComponent from "../components/RealizationsSection";
import EducationSectionComponent from "../components/EducationSection";
import ExperienceSectionComponent from "../components/ExperienceSection";
import { useWindowScroll } from "@mantine/hooks";
import { Affix, Button, rem, Transition } from "@mantine/core";
import { IconArrowUp } from "@tabler/icons-react";
import { isMobile } from "react-device-detect";

interface MainPageProps {}

const MainPage: FunctionComponent<MainPageProps> = () => {
  const [currentComponentHover, setCurrentComponentHover] =
    useState<string>("");

  const changeCurrentHoverComponent = (component: string) => {
    setCurrentComponentHover(component);
  };

  // Affix
  const [scroll, scrollTo] = useWindowScroll();

  return (
    <div
      className="min-h-screen  bg-slate-900 text-white"
      style={{ fontFamily: "'EB Garamond', serif" }}
    >
      {/* // ! ===================== Top section ===================== */}
      <div className={`${isMobile ? "px-2" : "px-12"}`}>
        <TopSection />
      </div>

      {/* // ! ===================== Desktop layout ===================== */}
      {!isMobile && (
        <div className="grid grid-cols-12">
          {/* // ! ===================== Left section ===================== */}

          {!isMobile && (
            <div className="col-span-2">
              {/* // ! ===================== Menu =====================*/}
              <span className="fixed bottom-0 left-0 z-50 h-screen flex flex-col justify-center">
                <MenuComponent
                  currentComponent={currentComponentHover}
                  changeComponent={changeCurrentHoverComponent}
                />
              </span>
            </div>
          )}

          {/* // ! ===================== Middle section ===================== */}
          <div className={`${isMobile ? "col-span-11" : "col-span-8"}`}>
            {/* // ! ===================== Links to socials ===================== */}
            <span className="fixed bottom-0 right-0 z-50 h-screen flex flex-col justify-center gap-y-5">
              <SocialLinksComponent />
            </span>

            {/* // ! ===================== Main part ===================== */}
            <div className="grid grid-cols-12 my-6">
              <div className="col-span-3 px-4 flex flex-col gap-y-10">
                <ContactSectionComponent
                  currentComponent={currentComponentHover}
                  changeComponent={changeCurrentHoverComponent}
                />

                <LanguagesSetcionComponent
                  currentComponent={currentComponentHover}
                  changeComponent={changeCurrentHoverComponent}
                />

                <HobbiesSectionComponent
                  currentComponent={currentComponentHover}
                  changeComponent={changeCurrentHoverComponent}
                />

                <ExperienceSectionComponent
                  currentComponent={currentComponentHover}
                  changeComponent={changeCurrentHoverComponent}
                />
              </div>

              <div className="col-span-9 flex flex-col gap-y-10">
                <AboutMeSectionComponent
                  currentComponent={currentComponentHover}
                  changeComponent={changeCurrentHoverComponent}
                />

                <RelizationsSectionComponent
                  currentComponent={currentComponentHover}
                  changeComponent={changeCurrentHoverComponent}
                />

                <EducationSectionComponent
                  currentComponent={currentComponentHover}
                  changeComponent={changeCurrentHoverComponent}
                />

                <TechnologiesSectionComponent
                  currentComponent={currentComponentHover}
                  changeComponent={changeCurrentHoverComponent}
                />
              </div>
            </div>
          </div>

          {/* Right section */}
          <div className="col-span-2"></div>
        </div>
      )}

      {/* // ! ===================== Mobile layout ===================== */}
      {isMobile && (
        <div className="ms-1 pt-10">
          {/* Contact and about me */}
          <div className="grid grid-cols-12 gap-x-2">
            <div className="col-span-4">
              <ContactSectionComponent
                currentComponent={currentComponentHover}
                changeComponent={changeCurrentHoverComponent}
              />
            </div>
            <div className="col-span-8">
              <AboutMeSectionComponent
                currentComponent={currentComponentHover}
                changeComponent={changeCurrentHoverComponent}
              />
            </div>
          </div>

          {/* Realizations */}
          <div className="pb-5">
            <RelizationsSectionComponent
              currentComponent={currentComponentHover}
              changeComponent={changeCurrentHoverComponent}
            />
          </div>

          {/* Languages and hobbies */}
          <div className="grid grid-cols-2 gap-x-4 pb-12">
            <div>
              <LanguagesSetcionComponent
                currentComponent={currentComponentHover}
                changeComponent={changeCurrentHoverComponent}
              />
            </div>
            <div>
              <HobbiesSectionComponent
                currentComponent={currentComponentHover}
                changeComponent={changeCurrentHoverComponent}
              />
            </div>
          </div>

          {/* Experience and education */}
          <div className="grid grid-cols-2 gap-x-4 pb-12">
            <div>
              <EducationSectionComponent
                currentComponent={currentComponentHover}
                changeComponent={changeCurrentHoverComponent}
              />
            </div>

            <div>
              <ExperienceSectionComponent
                currentComponent={currentComponentHover}
                changeComponent={changeCurrentHoverComponent}
              />
            </div>
          </div>

          {/* Technologies */}
          <div className="pb-10">
            <TechnologiesSectionComponent
              currentComponent={currentComponentHover}
              changeComponent={changeCurrentHoverComponent}
            />
          </div>
        </div>
      )}

      {/* Affix */}
      <Affix position={{ bottom: 20, right: 20 }}>
        <Transition transition="slide-up" mounted={scroll.y > 0}>
          {(transitionStyles) => (
            <Button
              leftSection={
                <IconArrowUp style={{ width: rem(16), height: rem(16) }} />
              }
              style={transitionStyles}
              onClick={() => scrollTo({ y: 0 })}
              size={isMobile ? "xs" : "md"}
            >
              Scroll to top
            </Button>
          )}
        </Transition>
      </Affix>
    </div>
  );
};

export default MainPage;
